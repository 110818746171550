export const en = {
  language: "English",
  home: {
    wishing: "All the best for your business.",
    TASCPartnersWithSoftwareSolutionProviders:
      "TASC partners with software solution providers",
    homeText:
      "give you the best functionality to meet your company needs.You can now run your business the way you want using the bestsolutions on the market.",
    contactOurExpert: "Contact our expert",
    aboutus: "About us",
    tascCompanyInformation: "Tasc Company Information",
    firstInfo:
      " Established in the year 1990, Tasc developed and implementedspecialized ERP software solutions and offers consultationservices in the information technology and financial fields. Theexperience that Tasc has gained over the years contributed tothe excellent customer satisfaction that our solutions enjoy inthe market.",
    secondInfo:
      "Continuous evolution and modernization are Tasc’s philosophy.Tasc uses the most potent ORACLE Database. Tasc’s systems havea standard: the flexibility and ease available to users of thesystems and the intensity of the reports in terms of quantity toserve business owners.",
    seeMore: "See more",
    generalManager: "General Manager",
    firstUl:
      "Rank your customer buy the average time they take to pay so you can brevet a cash flow problem",
    secondUrl: "Preventing a loss on a certain product or service.",
    thirdUrl:
      "Providing the shareholders with a detailed report for the company growth wherever it is.",
    firstAccount:
      "You can construct any type of report you need using our report architecture module.",
    secondAccount:
      "All data entry must be given approval by the account manager and then can be added to the database.",
    thirdAccound:
      "  The user interface is designed keeping in mind the time and energy cost for the entry process.",
    firstIT:
      "The software is using oracle database for its security and its DBM.",
    secondId: "The user is designed to be easily learnable.",
    thirdIT:
      "25 years’ experience in the market and our software is error free.",
    latestPosts: "Latest Posts",
    beYourself: "Be Yourself, Everyone Else Is Already Taken.",
    viewpost: "View post",
    Advantages:"Advantages",
    AccountingManager:"Accounting Manager",
    ITManager:"IT Manager"

  },
  aboutus: {
    tascActivity: "Tasc Activity",
    programDevelopment: "Program Development",
    ITConsultancy: "IT Consultancy",
    itContent:
      "Tasc Consultancy Department provides services in various areas. We offer consultancy services in fields like ITsecurity and privacy, databases, business re-engineering, andweb applications. Our specialists address our clients’specific needs and provide solutions suitable for the clients’market but with technologies at the international standards.",
    developer:
      "Tasc develops various types and sizes of software packagesfor the Middle East and North Africa markets with the latesttechnologies available. Tasc’s solutions are flexible,adaptable, and provide an abundance of reports and statisticsto help decision-makers grow their organizations",
    SafeBackup: "Safe Backup",
    AnytimeAnywhere: "Anytime, Anywhere",
    ContinuousUpdates: "Continuous Updates",
    SafeBackupContant:
      "Ensures the safety of the data by making regular backups through the system.",
    AnytimeAnywhereContant:
      "You only need an internet connection and you will be able to do your work. You can work on any device anytime from anywhere.",
    ContinuousUpdatesContant:
      "Get the latest software updates up-to-date and enjoy the constantly evolving software features.",
  },
  registration: {
    register: "Register ",
    signup: "Sign up !",
    createAnAccount: "Create An Account",
    Name: "Name",
    Surname: "Surname",
    companyInfo: "Company info",
    lastName: "Last Name",
    mobileNumber: "Phone Number",
    sector: "sector ",
    role: "Role",
    finish: "Finish",
    mainUserInfo: "Main user info",
    whatsAppNumber: "WhatsApp Number",
    companyName: "Company Name",
    password: "Password",
    confirmPassword: "Confirm Password",
    work: "Work / Field",
    field: "Field",
    description: "Description",
    country: "Country",
    city: "City",
    next: "Next",
    address: "Address",
    companyPhoneNumber: "Company Phone Number",
    commercialRecord: "Commercial Record ",
    uploadCompanyLogo: "Upload Company Logo( Optional )",
    optional: "Optional",
    acceptTermsOfServiceAndPrivacyPolicy:
      "Accept Terms of Service and Privacy Policy",
    alreadyHaveAnAccount: "Already Have An Account?",
    signIn: "Sign In",
    email: "Email",
    job: "Job",
    companyMail: "Company Mail ( Optional )",
    companyWebsite: "Company Website",
    companyWebsiteOptional: "Company Website (Optional)",
    company: "Company",
    individual: "Individual",
    buyer: "Buyer",
    Contractor: "Contractor",

    admin: "Admin",
    employee: "Employee",
    Supplier: "Supplier",
    userType: "User type",
    commercialRecordOptional: "commercialRecord (Optional)",
    organisationLegalStructure: "Organisation Legal Structure",
    pleaseChooseCountryFirst: "please Choose Country First",
    PleaseFillAllRequiredFields: "please Fill All Required Fields *",
    pleaseFillFirstName: "* Please fill firstName",
    pleaseFillLastName: "* Please fill lastName",
    pleaseChooseCompanyName: "* please Choose CompanyName",
    PleaseChooseWork: "* Please Choose Work",
    pleaseFillmobileNumber: "* please Fill mobileNumber",
    pleaseFillEmail: "* please Fill Email",
    pleaseFillPassword: "* please Fill Password",
    pleaseFillConfirmPassword: "* please Fill Confirm Password",
    pleaseChooseCompanyType: "* Please Choose Company Type",
    pleaseFillCompanyWebsite: "* please Fill CompanyWebsite",
    pleaseFillCountry: "* please Fill Country",
    pleaseChooseGovernorate: "* Please Choose Governorate",
    pleaseFillAddress: "* please Fill Address",
    pleaseUploadCommercialRecord: "* please Upload CommercialRecord",
    pleaseChooseYourRole: "* please Choose Your Role  ",
  },
  footer: {
    copyRights: "2021 Shayyek. All Rights Reserved",
  },

  navbar: {
    login: "Login",
    contactus: "Contactus",
    pricing: "Pricing",
    blog: " Blog ",
    aboutus: " Aboutus",
    home: "Home ",
  },
  contactus: {
    ourContactus: "Our Contact us",
    home: "Home",
    contactus: "contact us",
    pragraph:
      "We’re glad to discuss your organisation’s situation. So please contact us via the details below, or enter your request.",
    name: "Name",
    email: "E-mail",
    phoneNumber: "Phone Number",
    yourQuestion: "Your Question",
    submit: "Submit",
    alert: "Please fill your filed",
    touch: "Get in touch for any kind of help and information",
    addresstitle: " Our head office address:",
    address: "3556 Hartford Way Village, Mount Pleasant, SC, 29466, Australia.",
    callHelping: "Call For Help",
    mailInfotitle: " Mail us for information",
  },
  blog: {
    latestPosts: " latest posts",
  },
  login: {
    signin: "Sign in Now!",
    loginNow: "Login Now",
    userName: "User Name",
    password: "Password",
    forgetPassword: "Forget Password ?",
    registerNow: "Register Now !",
    RememberMe: "Remember me",
    loginContent:
      " Log in to your account so you can continue using our customer experience.",
  },
  pricing: {
    applicationPrices: "Application Prices",
    implementationPrices: "Implementation Prices",
    firstPrice: "The system monitors the entire supply process.",
    secondPrice: "Makes sure that the medicines are “not adulterated”.",
    thirdPrice:
      "Provides reliable data regarding the Medicines used in fraud and their sources.",
  },
  meeting:{
    meeting:"i want a meeting with tasc expert at",
    direction:"Duration  :1 hour",
    meetingDetails:"Schedule a 1-hour free meeting with an expert, to get:",
    firstRole:"a tailored demonstration",
    secondRole:"recommendations based on your needs",
    thirdRole:"answers to your questions about Odoo",
    fourthRole:"information about pricing & methodology",
    time:"Time displayed in",
    timeCity:"Istanbul/Turkey",
    timeZone:"timezone",
    availibleTime:"Available times on ",
    Confirm:"Confirm",
    Confirmation: "Confirm your details ",
    wantameeting:"i want a meeting with tasc expert on",
   Website: "website",
   alert:"please fill all fields",
   companyWebsite: "Company website",
   yourIndustry:"Your Industry",
   describeYourNeeds:"Describe Your Needs:",
   WhoIsItFor:"Who Is It For ?",
   MyCompany:"My Company",
   Other:" Other",
   OneofmyCustomers:"One of my Customers",
   ConfirmAppointment:"Confirm Appointment",
   leasechooseyouravalibletime:"please choose your avalible time",
   pleasechooseyouravalibleday:"please choose your avalible day",

  },
  post:{
    Recentposts:"Recent posts",
    firstPragraph:"Imitation of medicines, their waste, commercial fraud, and all formsof corruption in medicine have come to an end with the dawn of smarttechnological systems. The Kingdom of Saudi Arabia has begun to takepractical steps to urge pharmaceutical companies to register with theSaudi Numbering Center. According to the Food and Medicine Authorityregulations, they will update their data and product lists through theMedicine tracking system with an immediate Medicine control plan.",
  secondPragraph:"  This system is part of the Food and Medicine Authority’s plan to participate in the National Transformation Program 2020, aiming to achieve Saudi Vision 2030.",
  thirdPragraph:"   Learn with us about this plan and the new “RSD” Medicine tracking system, its importance, and its most prominent advantages.",
compatibleData:"Content without backward-compatible data.",
Relatedposts:"Related posts",
firstData:"heir community. I wonder how they wou if their own child witnessed such a level of violence?",
secondData:"  “There is absolutely no justification for an attack like this in our communities and we must all work together to bring those responsible to justice and to stop this from happening to another child.”",
thirdData:"Earlier this month, the PSNI launched a hard-hitting advertisement campaign aimed at changing public attitudes to paramilitary attacks.",
firstList:"The system monitors the entire supply process.",
secondList:"Makes sure that the medicines are “not adulterated”.",
thirdList:"Provides reliable data regarding the Medicines used in fraud and their sources.",
fourthList:"Gives us information about the availability of the Medicine’s location in a short time.",
fifthList:"Enables the consumer to know all the Medicine data.",
sixthList:"Helps to optimize the use of pharmaceuticals, and reduce waste.",
seventhList:" Immediately stops the circulation of withdrawn medicines. It ensures no circulation happens by giving a warning.",
eighthList:"The “RSD” Medicine tracking system allows the consumer to inquire about a Medicine’s safety and report any side effects after it use.",
Sharepost:"Share post",
category:"category",
}
};
