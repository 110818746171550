export const ar = {
  language: "العربيه",
  home: {
    wishing: "كل التوفيق لعملك.",
    TASCPartnersWithSoftwareSolutionProviders:
      "شركاء TASC مع موفري حلول البرامج",
    homeText:
      "يمنحك أفضل الوظائف لتلبية احتياجات شركتك.يمكنك الآن إدارة عملك بالطريقة التي تريدها باستخدام الأفضلحلول في السوق.",
    contactOurExpert: "اتصل بخبيرنا",
    aboutus: "معلومات عنا",
    tascCompanyInformation: "معلومات شركة تاسك",
    firstInfo:
      "تأسست في عام 1990 ، طورت ونفذت تاسكحلول برمجيات تخطيط موارد المؤسسات المتخصصة وعروض الاستشاراتخدمات في مجال تكنولوجيا المعلومات والمالية. الالخبرة التي اكتسبتها تاسك على مر السنينرضا العملاء الممتاز الذي تتمتع به حلولناالسوق.",
    secondInfo:
      "التطور المستمر والتحديث هي فلسفة تاسك.يستخدم Tasc قاعدة بيانات ORACLE الأكثر فعالية. تمتلك أنظمة تاسكمعيار: المرونة والسهولة المتاحة لمستخدميالأنظمة وكثافة التقارير من حيث الكمية إلىخدمة أصحاب الأعمال.",
    seeMore: "شاهد المزيد",
    generalManager: "مدير عام",
    firstUl:
      "رتب عميلك عند شراء متوسط ​​الوقت الذي يستغرقه في الدفع حتى تتمكن من تجنب مشكلة التدفق النقدي",
    secondUrl: "منع خسارة منتج أو خدمة معينة.",
    thirdUrl: "تزويد المساهمين بتقرير مفصل عن نمو الشركة أينما وجدت.",
    firstAccount:
      "يمكنك إنشاء أي نوع من التقارير تحتاجه باستخدام وحدة هندسة التقارير الخاصة بنا.",
    secondAccount:
      "يجب الحصول على موافقة مدير الحساب على جميع عمليات إدخال البيانات ، ومن ثم يمكن إضافتها إلى قاعدة البيانات.",
    thirdAccound:
      "تم تصميم واجهة المستخدم مع الأخذ في الاعتبار الوقت وتكلفة الطاقة لعملية الدخول.",
    firstIT: "يستخدم البرنامج قاعدة بيانات أوراكل لأمنها وأمنها DBM.",
    secondId: "تم تصميم المستخدم ليكون سهل التعلم.",
    thirdIT: "25 عامًا من الخبرة في السوق وبرنامجنا خالٍ من الأخطاء.",
    latestPosts: "آخر المشاركات",
    beYourself: "كن نفسك وليس نسخه مقلدة من شخص اخر.",
    viewpost: "شاهد المنشور",
    Advantages:"مزايا",
    AccountingManager:"مدير المحاسبة",
    ITManager:"مدير تكنولوجيا المعلومات",
  },
  aboutus: {
    tascActivity: "نشاط تاسك",
    programDevelopment: "تطوير البرامج",
    ITConsultancy: "استشارات تكنولوجيا المعلومات",
    itContent:
      "يقدم قسم الاستشارات في تاسك خدماته في مختلف المجالات. نحن نقدم خدمات استشارية في مجالات مثل أمن وخصوصية تكنولوجيا المعلومات ، وقواعد البيانات ، وإعادة هندسة الأعمال ، وتطبيقات الويب. يلبي المتخصصون لدينا الاحتياجات الخاصة لعملائنا ويقدمون حلولًا مناسبة لسوق العملاء ولكن باستخدام تقنيات وفقًا للمعايير الدولية.",
    developer:
      "تطور تاسك أنواعًا وأحجامًا مختلفة من حزم البرامج لأسواق الشرق الأوسط وشمال إفريقيا بأحدث التقنيات المتاحة. تتميز حلول تاسك بالمرونة والقابلية للتكيف وتوفر وفرة من التقارير والإحصاءات لمساعدة صانعي القرار على تنمية مؤسساتهم",
    SafeBackup: "النسخ الاحتياطي الآمن",
    AnytimeAnywhere: "في أي وقت ، في أي مكان",
    ContinuousUpdates: "تحديثات مستمرة",
    SafeBackupContant:
      "يضمن سلامة البيانات من خلال عمل نسخ احتياطية منتظمة من خلال النظام.",
    AnytimeAnywhereContant:
      "تحتاج فقط إلى اتصال بالإنترنت وستكون قادرًا على أداء عملك. يمكنك العمل على أي جهاز في أي وقت ومن أي مكان.",
    ContinuousUpdatesContant:
      "احصل على أحدث تحديثات البرامج واستمتع بميزات البرامج المتطورة باستمرار.",
  },
  registration: {
    register: "تسجيل ",
    signup: "سجل  !",
    Surname: "اسم العائلة",
    createAnAccount: "انشاء حساب جديد",
    finish: "انتهاء",
    Name: "الاسم ",
    lastName: "الاسم الاخير",
    mainUserInfo: "معلومات المستخدم الرئيسية",
    companyInfo: "معلومات الشركة",
    sector: "قطاع",
    mobileNumber: "رقم الهاتف",
    role: "الدور",
    whatsAppNumber: "رقم الواتس اب",
    companyName: "اسم الشركة",
    password: "الرقم السرى",
    confirmPassword: "تاكيد الرقم السرى",
    work: " العمل / الحقل",
    field: "المجال",
    description: "الوصف",
    country: "البلد",
    city: "المحافظة",
    next: "التالي",
    address: "العنوان",
    companyPhoneNumber: "رقم الشركة",
    commercialRecord: "سجل تجارى",
    commercialRecordOptional: " (اختياري)سجل تجارى",
    uploadCompanyLogo: "حمل لوجو الشركة (اختياري)",
    optional: "اختياري",
    acceptTermsOfServiceAndPrivacyPolicy: "قبول شروط الخدمة و الخصوصية",
    alreadyHaveAnAccount: "هل لديك حساب ؟",
    signIn: "تسجيل الدخول",
    email: "البريد الالكتروني",
    job: "الوظيفة",
    companyMail: "البريد الالكتروني للشركة (اختياري)",
    Website: "موقع  الالكتروني ",
    companyWebsiteOptional: "موقع الالكتروني للشركة (اختياري)",
    company: "شركة",
    individual: "فرد",
    buyer: "مشترى",
    Contractor: "مقاول",
    admin: "المسؤل",
    employee: "موظف",
    Supplier: "مورد",
    userType: "نوع المستخدم",
    organisationLegalStructure: "نوع الشركة القنوني",
    pleaseChooseCountryFirst: "برجاء اختيار البلد اولا",
    pleaseFillAllRequiredFields: "برجاء املئ جميع الخانات *",
    pleaseFillFirstName: "* برجاء املئ الاسم الاخير",
    pleaseFillLastName: "* برجاء املئ الاسم الاخير",
    pleaseChooseCompanyName: "* برجاء اختار اسم الشركة",
    pleaseChooseWork: "* برجاء اختار مجالات العمل ",
    pleaseFillmobileNumber: "* برجاء املئ رقم الهاتف ",
    pleaseFillEmail: "* برجاء املئ  البريد الالكتروني ",
    pleaseFillPassword: "* برجاء املئ كلمة السر ",
    pleaseFillConfirmPassword: "* برجاء املئ تأكيد كلمة السر ",
    pleaseChooseCompanyType: "* برجاء اختار نوع  الشركة ",
    pleaseFillCompanyWebsite: "* برجاء املئ موقع  الالكتروني للشركة",
    pleaseChooseCountry: "* برجاء اختار البلد",
    pleaseChooseGovernorate: "* برجاء اختار المحافظه",
    pleaseFillAddress: "* برجاء املئ العنوان ",
    pleaseUploadCommercialRecord: "* برجاء رفع سجل الشركة ",
    pleaseChooseYourRole: "* برجاء اختار الوظيفة  ",
  },
  footer: {
    copyRights: "٢٠٢١ شيك , كل الحقوق محفوظه",
  },
  blog: {
    latestPosts: " اخر المشاركات",
  },

  navbar: {
    login: "تسجيل الدخول",
    contactus: "اتصل بينا",
    pricing: "السعر",
    blog: " المقالات ",
    aboutus: "معلومات عنا",
    home: "الرئيسيه ",
  },
  contactus: {
    ourContactus: "اتصل بينا",
    home: "الرئيسيه",
    contactus: "اتصل بينا",
    pragraph:
      "يسعدنا مناقشة وضع مؤسستك. لذا يرجى الاتصال بنا عبر التفاصيل أدناه ، أو إدخال طلبك.",
    name: "الاسم",
    email: "البريد الالكتروني",
    phoneNumber: "رقم الهاتف",
    yourQuestion: "سؤالك",
    submit: "ارسال",
    alert: "برجاء ملئ جميع الحقول",
    touch: "تواصل معنا للحصول على أي نوع من المساعدة والمعلومات",
    addresstitle: "عنوان مكتبنا الرئيسي:",
    address: "3556 هارتفورد واي فيليدج ، ماونت بليزانت ، 29466 ، أستراليا.",
    callHelping: "اطلب المساعدة",
    mailInfotitle: " راسلنا للحصول على معلومات",
  },
  login: {
    signin: " سجل الدخول الان",
    login: "تسجيل",
    userName: "اسم المستخدم",
    password: "رقم المرور",
    loginNow: "سجل الدخول الان",
    forgetPassword: "هل نسيت كلمة المرور ؟",
    registerNow: "سجل الان !",
    RememberMe: "تذكرني",
    loginContent:
      "قم بتسجيل الدخول إلى حسابك حتى تتمكن من الاستمرار في استخدام تجربة العملاء لدينا.",
  },
  pricing: {
    applicationPrices: "أسعار التطبيق",
    implementationPrices: "أسعار التنفيذ",
    firstPrice: "يراقب النظام عملية التوريد بأكملها.",
    secondPrice: "تأكد من أن الأدوية غير مغشوشة .",
    thirdPrice:
      "يوفر بيانات موثوقة بشأن الأدوية المستخدمة في الاحتيال ومصادرها.",
  },
  meeting: {
    meeting: "أريد لقاء مع خبير تاسك في",
    direction: "المدة: 1 ساعة",
    meetingDetails:
      "حدد موعدًا لاجتماع مجاني لمدة ساعة مع أحد الخبراء للحصول على:",
    firstRole: "مظاهرة مفصلة",
    secondRole: "توصيات بناء على احتياجاتك",
    thirdRole: "توصيات بناء على احتياجاتكإجابات على أسئلتك حول Odoo",
    fouethRole: "معلومات حول التسعير والمنهجية",
    timeZone: "الوقت المعروض بتوقيت اسطنبول / تركيا",
    timeCity: "اسطنبول / تركيا",
    time: "الوقت المعروض بتوقيت",
    availibleTime: "الأوقات المتاحة في",
    Confirm: "تأكيد",
    Confirmation: "قم بتأكيد التفاصيل الخاصة بك",
     wantameeting:"اريد لقاء مع خبراء تاسك في",
    Website: "موقع  الالكتروني ",
    companyWebsite: "موقع  الالكتروني الشركة",
    yourIndustry:"الصناعة الخاصة بك",
    describeYourNeeds:"صف احتياجاتك",
    WhoIsItFor:"لمن هذا ؟",
    alert:"برجاء ملئ جميع الخانات",
    MyCompany:"شركتي",
    Other:"أخري",
    OneofmyCustomers:"أحد عملائي",
    ConfirmAppointment:"تأكيد الموعد",
    pleasechooseyouravalibleday:"برجاء اختيار يوم مناسب",
    leasechooseyouravalibletime:"برجاء اختيار وقت مناسب"

  },
  post:{
    Recentposts:"المشاركات الاخيرة",
    thirdPragraph :"تعرف معنا على هذه الخطة وعن نظام تتبع الطب الجديد “RSD” وأهميتها وأبرز مميزاتها.",
    secondPragraph:"يأتي هذا النظام ضمن خطة هيئة الغذاء والدواء للمشاركة في برنامج التحول الوطني 2020 الهادف إلى تحقيق رؤية المملكة 2030.",
    firstPragraph:" انتهى تقليد الأدويةوإهدارها والغش التجاري وكافة أشكال الفساد في الطب مع بزوغ فجر الأنظمة التكنولوجية الذكية. بدأت المملكة العربية السعودية في اتخاذ خطوات عملية لحث شركات الأدوية على التسجيل في  مركز الترقيم السعودي. وفقًا للوائح هيئة الغذاء والدواء ، سيقومون بتحديث بياناتهم وقوائم المنتجات الخاصة بهم من خلال نظام تتبع الأدوية مع خطة مراقبة طبية فورية.",
    compatibleData:"محتوى بدون بيانات متوافقة مع الإصدارات السابقة.",
    Relatedposts: "المنشورات ذات الصلة",
    firstData:"مجتمع الورثة. أتساءل كيف سيكونون لو شهد طفلهم مثل هذا المستوى من العنف؟",
    secondData:"لا يوجد أي مبرر على الإطلاق لهجوم مثل هذا في بلدنا المجتمعات ويجب علينا جميعًا العمل معًا لجلب المسؤولين للعدالة ومنع حدوث ذلك لطفل آخر.",
    thirdData:"في وقت سابق من هذا الشهر ، أطلقت PSNI إعلانًا قويًاتهدف الحملة إلى تغيير المواقف العامة تجاه الهجمات شبه العسكرية. ",
    firstList:"يقوم النظام بمراقبة عملية التوريد بأكملها.",
    secondList:" تأكد من أن الأدوية غير مغشوشة ." ,
    thirdList:"  يوفر بيانات موثوقة بشأن الأدوية المستخدمة في الاحتيال ومصادرها.",
    fourthList:" يعطينا معلومات حول توافر الأدوية الموقع في وقت قصير.",
    fifthList:" يُمكِّن المستهلك من معرفة جميع بيانات الدواء.",
    sixthList:" يساعد على تحسين استخدام المستحضرات الصيدلانية وتقليل الفاقد.",
    seventhList:" يوقف فورًا تداول الأدوية المسحوبة. هو - هي يضمن عدم حدوث تداول عن طريق إعطاء تحذير",
    eighthList:" يسمح نظام تتبع الطب RSD  للمستهلك بالاستعلام عن سلامة الدواء والإبلاغ عن أي آثار جانبية بعد استخدامه.",
    Sharepost:"مشاركة منشور",
    category:"الفئة"
  }
};




